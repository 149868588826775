export const colors = {
  orange: '#ffa600',
  orangeDark: '#e09302',
  orangeLight: '#d9a13b',
  offWhite: '#f0f8ff',
  primary: '#004089',
  background: '#FFFFFF',
  textPrimary: '#333333',
  textSecondary: '#6C757D',
  backdrop: '#000000FF',
  surface: '#F8F9FA',
  surfaceVariant: '#D4D4D4',
  onSurface: '#6C757D',
  onSurfaceVariant: '#404040',
  outline: '#A5A4A4',
  error: '#B91C1C',
  errorBackground: '#FEE2E2',
  black: '#000000',
}
